<template>
  <svg ref="parallelSvg" id="parallelSvg" :width="width" :height="height">
    <g :style="{'transform':'translate('+100+','+30+')'}">
      <g class="foreground">
        <path :class="{'active': path.active===true, 'decrease-width': decreaseWidth=== true, 'highlight-cord': path.highlightCord === true}" v-for="(path,index) in pathData" :key="'foreground'+index" :d="path.d" :stroke-opacity="path.strokeOpacity" ></path>
      </g>
    </g>
  </svg>
</template>

<script>
import { scalePoint } from 'd3-scale'
import { line } from 'd3-shape'
import { axisLeft } from 'd3-axis'

import { brushY, brushSelection } from 'd3-brush'

import { select, selectAll } from 'd3-selection'
import { mapGetters } from 'vuex'

// GLOBALS
let clearBrushFlag = false

const modelsWithinExtent = []
const activeDimensions = []

const widthConstant = 200

export default {
  name: 'parallelCoordChart',
  data () {
    return {
      margin: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      xScale: scalePoint(),
      yScale: {},
      dragging: {},
      line: line(),
      axis: axisLeft(),
      background: null,
      foreground: null,
      svg: null,
      pathData: [],
      decreaseWidth: false,
      yaxis: null,
      width: window.width
    }
  },
  props: {
    modelData: Array
  },
  mounted () {
    this.init()
    // calls the top 10 data results from the entire dataset equally weighted
    this.callDefaultModels()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    height () {
      return 300 - this.margin.top - this.margin.bottom
    },
    ...mapGetters({
      metricObject: 'getMetricObject',
      // inputObject: 'getInputObject',
      // inputHeaders: 'getInputHeaders',
      metricHeaders: 'getMetricHeaders'
    }),
    dimensions () {
      // return this.inputHeaders.concat(this.metricHeaders)
      return this.metricHeaders
    }
  },
  methods: {
    callDefaultModels () {
      this.updateSelectedModels(this.modelData, this.dimensions)
    },
    onResize () {
      let self = this

      self.width = document.getElementById('parallelContainer').getBoundingClientRect().width - widthConstant

      self.xScale.domain(self.dimensions).range([0, self.width])

      Object.values(self.yScale).forEach((scale) => {
        scale.range([self.height, 0])
      })

      function position (d) {
        var v = self.dragging[d]
        return v == null ? self.xScale(d) : v
      }

      function path (d) {
        return self.line(self.dimensions.map(function (p) {
          return [position(p), self.yScale[p](d[p])]
        }))
      }

      this.pathData = this.modelData.map(function (objectData) {
        // passes data object through
        objectData.active = true
        objectData.strokeOpacity = 0.3
        // should return path for svg
        objectData.d = path(objectData)
        return objectData
      })

      var g = this.svg.selectAll('.dimension')
        .attr('transform', function (d) {
          return 'translate(' + self.xScale(d) + ')'
        })

      g.selectAll('.axis').each(function (d) {
        // check if metric object
        if (self.metricObject[d]) {
          const ticks = self.metricObject[d].ticks
          const min = self.yScale[d].invert(self.height)
          const max = self.yScale[d].invert(0)

          select(this).call(self.axis.scale(self.yScale[d])
            .tickValues([min, max])
            .tickFormat((t, i) => {
              return i === 0 ? ticks[0] : ticks[ticks.length - 1]
            })
          )

          // check if input object
        }
        // else if (self.inputObject[d]) {
        //   select(this).call(self.axis.scale(self.yScale[d])
        //     .tickValues(Object.keys(self.inputObject[d].marks))
        //     .tickFormat((n) => {
        //       return self.inputObject[d].marks[n] !== undefined ? self.inputObject[d].marks[n].label : ' '
        //     }))
        // }
      })
    },
    init () {
      // this.width = window.innerWidth - this.margin.left - this.margin.right
      this.width = document.getElementById('parallelContainer').getBoundingClientRect().width - widthConstant

      this.svg = select('#parallelSvg').append('g')

      this.createColumns(this.modelData, this.xScale, this.yScale, this.height, this.metricObject, this.dimensions, this.dragging, this.axis, this.line, this.width, this.brushEnd, this.brushMove, this.updateSelectedModels)

      window.addEventListener('resize', this.onResize)
    },
    updateSelectedModels (selectedModels, dimensions) {
      this.$emit('updateSelectedModels', selectedModels, dimensions)
    },
    createColumns (modelData, xScale, yScale, height, metricObject, dimensions, dragging, axis, line, width, brushEnd, brushMove, updateSelectedModels) {
      xScale.domain(dimensions).range([0, width])

      const labels = {}

      // this.inputHeaders.forEach((header) => {
      //   labels[header] = inputObject[header].label
      //   yScale[header] = inputObject[header].scaleLinear
      //   yScale[header].range([height, 0])
      // })

      this.metricHeaders.forEach((header) => {
        labels[header] = metricObject[header].label
        yScale[header] = metricObject[header].scaleLinear
        yScale[header].range([height, 0])
      })

      this.pathData = modelData.map((objectData) => {
        // passes data object through
        objectData.active = true
        objectData.strokeOpacity = 0.3
        // should return path for svg
        objectData.d = path(objectData)

        return objectData
      })

      let g = this.svg.selectAll('.dimension')
        .data(dimensions)
        .enter().append('g')
        .attr('class', 'dimension')
        .attr('transform', function (d) {
          return 'translate(' + xScale(d) + ')'
        })

      // Add an axis and title.
      this.yaxis = g.append('g')
        .attr('class', 'axis')
        .each(function (d) {
          // check if metric object
          if (metricObject[d]) {
            const ticks = metricObject[d].ticks
            const min = yScale[d].invert(height)
            const max = yScale[d].invert(0)

            select(this).call(axis.scale(yScale[d])
              .tickValues([min, max])
              .tickFormat((t, i) => {
                return i === 0 ? ticks[0] : ticks[ticks.length - 1]
              })
            )

          // check if input object
          }

          // else if (inputObject[d]) {
          //   select(this).call(axis.scale(yScale[d])
          //     .tickFormat((n) => {
          //       return inputObject[d].marks[n] !== undefined ? inputObject[d].marks[n].label : ' '
          //     }))
          // }
        })
        .append('text')
        .attr('class', 'dimension-label sublabel')
        .style('text-anchor', 'middle')
        .attr('y', -15)
        .text(function (d) {
          return labels[d]
        })

      // Add and store a brush for each axis in the y object
      g.append('g')
        .attr('class', 'brush')
        .each(function (d) {
          select(this).call(yScale[d].brush = brushY()
            .extent([[-10, 0], [10, height]])
            // .on("start", brushstart)
            .on('brush', brushMove)
            .on('end', function () {
              // checks to see if brushes are being cleared
              // only call when clearBrush is not running
              if (!clearBrushFlag) {
                const b = brushEnd()

                if (b['models'].length > 0) {
                  // we have data!
                  updateSelectedModels(b['models'], b['dimensions'])
                }
              }
            })
          )
        })
        .selectAll('rect')
        .attr('x', -8)
        .attr('width', 16)

      function position (d) {
        var v = dragging[d]
        return v == null ? xScale(d) : v
      }

      function path (d) {
        return line(dimensions.map(function (p) {
          return [position(p), yScale[p](d[p])]
        }))
      }
    },
    /**
     * @default {void}
     *  calls brush.move
     *  clears brush extents and resets the chart
     *  calls default models
     */
    clearBrushes () {
      clearBrushFlag = true

      this.dimensions.forEach((d) => {
        selectAll('.brush').call(this.yScale[d].brush.move, null)
      })

      clearBrushFlag = false

      this.callDefaultModels()
    },
    toggleWidth (flag) {
      this.decreaseWidth = flag
    },
    highlightLineByID (ID, toggle) {
      if (toggle) {
        this.pathData = this.pathData.map((d) => {
          let matchID = parseInt(d['iteration'])
          ID = parseInt(ID)

          d['highlightCord'] = matchID === ID
          d['strokeOpacity'] = matchID === ID ? 1 : 0
          return d
        })
      } else {
        this.pathData = this.pathData.map((d) => {
          d['highlightCord'] = false
          d['strokeOpacity'] = 0.3
          return d
        })
      }
    },
    brushMove () {
      // tracts brush extents
      var extents = []
      // tracks active dimensions
      activeDimensions.length = 0

      var yScale = this.yScale
      var decreaseWidth = this.toggleWidth

      var increaseWidthValue = 25
      // tracks models within extent for API call
      // modelsWithinExtent = []
      modelsWithinExtent.length = 0

      // select all brushes and loop through for current extents
      this.svg.selectAll('.brush')
        .filter(function (d) {
          return brushSelection(this)
        })
        .each(function (d) {
          activeDimensions.push(d)
          extents.push([yScale[d].invert(brushSelection(this)[0]), yScale[d].invert(brushSelection(this)[1])])
        })
      // set active dimensions to instance
      // activeDimensions = ad

      /**
       * Loops through the active brushes and sees which
       * paths are within the given bounds of each brush
       *
       * inner loop check returns True/False
       * outer loop returns either inline or non display style
       */
      this.pathData = this.pathData.map((d) => {
        if (activeDimensions.length <= 0) {
          d.active = true
        } else {
          activeDimensions.every((p, i) => {
            var value = d[p]
            if (extents[i][1] <= parseFloat(value) && parseFloat(value) <= extents[i][0]) {
              d.active = true
              return true
            } else {
              d.active = false
              return false
            }
          })

          if (d.active === true) {
            // this array contains current models iteration numbers within brush extent
            if (modelsWithinExtent.length <= increaseWidthValue) {
              decreaseWidth(true)
            } else {
              decreaseWidth(false)
            }
            modelsWithinExtent.push(d)
          }
        }
        return d
      })
    },
    // creates two arrays that track which dimensions are active
    // and which extents are within the bounds of the active brushes
    brushEnd (type) {
      return { 'models': modelsWithinExtent, 'dimensions': activeDimensions }
    }
  }
}
</script>

<style lang="scss">
$text-shadow-color: black;

#parallelSvg{
  overflow: visible;
}
#parallelContainer{
  z-index: 2;
}
.foreground path{
  fill:none;
  stroke:rgba(250, 250, 250, 0.01);
  stroke-width: 1px;

  &.active{
    stroke: $highlight-color!important;
  }

  &.active.decrease-width{
    stroke-width: 4px!important;
  }

  &.highlight-cord{
    stroke: $highlight-color!important;
    stroke-width: 5px;
    stroke-linecap: round;
  }
}
.brush .extent{
  fill-opacity: 0.3;
  stroke: $white;
  shape-rendering: crispEdges;
}
.axis line,
.axis path{
  fill: none;
  stroke:$border-color;
}

.axis text{
  fill: $white;
  user-select: none;
  pointer-events: none;

  text-shadow: 0 1px 0 $text-shadow-color, 1px 0 0 $text-shadow-color, 0 -1px 0 $text-shadow-color, -1px 0 0 $text-shadow-color;
}
.axis .tick{
  user-select: none;

  pointer-events: none;

  font-family: 'Roboto Mono', monospace;
  font-size: 0.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
}
</style>
