<template>
  <div id="filterCard" class="d-flex flex-row justify-content-between">
    <div
      class="card-scene-parent"
      :class="{ active: filterCardActive === true }"
      @mouseenter="highlightChart(true)"
      @mouseleave="highlightChart(false)"
    >
      <div class="card-scene" :ref="sceneIndex" :id="sceneIndex"></div>
      <div class="card-scene-buttons d-flex justify-content-end">
        <!-- <div class="icon-svg mr-2">
          <collectionHeart :currentModelID="parseModelID" />
        </div> -->
        <div class="icon-svg mr-2">
          <exploreTelescope :currentModelID="parseModelID" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @vue-import heart child component
 */
// import collectionHeart from '@/components/Utility/collection_heart.vue'
import exploreTelescope from '@/components/Utility/explore_telescope.vue'

export default {
  name: 'filterCard',
  props: {
    modelID: String
  },
  components: {
    // collectionHeart,
    exploreTelescope
  },
  data () {
    return {
      filterCardActive: false
    }
  },
  computed: {
    sceneIndex () {
      return 'filter-card-index-' + this.modelID
    },
    parseModelID () {
      return parseInt(this.modelID)
    }
  },
  methods: {
    highlightChart (toggle) {
      this.filterCardActive = toggle === true
      this.$emit('highlightChart', this.modelID, toggle)
    }
  }
}
</script>

<style lang="scss">
$card-size: 300px;
$card-width: calc(#{$card-size + 100px});

#filterCard {
  .card-scene-parent {
    border: 1px solid $white;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-color: $highlight-color;
    }
  }
  .card-scene {
    height: $card-size;
    width: $card-width;
    min-width: $card-width;
    background: transparent;
  }
  .card-scene-buttons {
    position: relative;
    bottom: $font-md;
    right: $font-md;
  }
}
</style>
