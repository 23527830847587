<template>
  <div ref="filterMode" class="filterMode" id="filterMode">
    <div class="canvas-spacer lg"></div>
    <div
      class="top-container d-flex text-left p-2 justify-content-between flex-row align-items-center"
    >
      <div class="">
        <div class="d-flex flex-row align-items-center">
          <h1 class="page-title">Filter Mode</h1>
          <div class="model-counter">
            {{ modelsWithinExtent }}<span>/{{ numberOfModels.length }}</span>
          </div>
        </div>
        <div class="description">
          Click and drag on the chart axis to adjust the model selection.
        </div>
      </div>
      <div class="clear-selection-button">
        <b-btn @click="callChildClearSelection" class="background-black"
          >Clear Selection</b-btn
        >
      </div>
    </div>
    <div class="row canvas-spacer md"></div>
    <div id="parallelContainer" class="w-100">
      <parallelCoordChart
        ref="parallelCoordChart"
        :modelData="modelData"
        @updateSelectedModels="readNewModels"
      />
    </div>
    <div id="cardContainer" ref="filter-card-container">
      <canvas ref="filterCanvas" id="canvasParent"></canvas>
      <div
        id="filterContent"
        class="container-fluid styled-scrollbar"
        ref="filter-content"
      >
        <div class="spacer-md"></div>
        <div class="card-row row mt-4" v-for="i in rowCount" :key="i">
          <div
            class="d-flex justify-content-center col-4 col-lg-4 col-md-6 col-sm-12 pr-4 pl-4"
            v-for="(modelIndex, index) in itemCountInRow(i)"
            :key="index"
          >
            <filterCard
              :modelID="modelIndex"
              @highlightChart="filterChartByModel"
            />
          </div>
        </div>
      </div>
    </div>
    <iconTooltip :buildData="tooltipBuildObject" />
  </div>
</template>

<script>
/**
 * @vue-import store mapping store to view
 */
import { mapGetters } from 'vuex'
/**
 * parallelCoordChart import
 */
import parallelCoordChart from '@/components/filter/parallelCoordChart.vue'
import filterCard from '@/components/filter/filter_card.vue'
// import helper functions
import { cleanScenes } from '@/assets/js/helper.js'

import iconTooltip from '@/components/filter/iconTooltip.vue'

import {
  onWindowResize,
  renderScenes,
  createScenes
} from '@/assets/js/sceneHelpers.js'
/**
 * import THREEjs library
 */
const THREE = require('three')
/**
 * import orbit controls library
 */
const OrbitControls = require('three-orbit-controls')(THREE)
let renderer = null
let scenes = []
let numberOfModelsToReturn = 8
let contextOBJ = null

export default {
  name: 'FilterMode',
  components: {
    parallelCoordChart,
    iconTooltip,
    filterCard
  },
  data () {
    return {
      cardsPerRow: 3,
      sceneBlack: new THREE.Color('rgb(24,24,24)'),
      sceneModels: []
    }
  },
  created () {
    this.modelsWithinExtent = 0
    this.tooltipBuildObject = {
      left: 0,
      top: 0,
      question: ''
    }

    this.subscribeToStore()
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.unsubscribeFromStore()
  },
  destroyed () {
    function empty (elem) {
      while (elem.lastChild) elem.removeChild(elem.lastChild)
    }
    empty(this.canvas)

    this.cleanGarbage()

    renderer.domElement.addEventListener('dblclick', null, false)
    renderer.renderLists.dispose()
    renderer.clear()

    this.content.removeEventListener('scroll', null, false)
    window.removeEventListener('resize', this.render, false)
  },
  computed: {
    ...mapGetters({
      modelData: 'getModelData',
      modelDataFlag: 'getModelDataFlag',
      modelSettings: 'getSettings',
      gridModels: 'getGridModels',
      modelIDs: 'getModelIDs',
      metricObject: 'getMetricObject',
      metricHeaders: 'getMetricHeaders',
      numberOfModels: 'getNumberOfModels',
      collectionIDs: 'getCollectionIDs',
      contextObject: 'getContextObject'
    }),
    rowCount: function () {
      return Math.ceil(this.sceneModels.length / this.cardsPerRow)
    },
    canvas () {
      return this.$refs['filterCanvas']
    },
    content () {
      return this.$refs['filter-content']
    }
  },
  methods: {
    init () {
      this.$store.commit('setLoadingFlag', true)
      this.canvas.classList.add('inactive')
      // this.content.addEventListener('scroll', this.animate)

      renderer = new THREE.WebGLRenderer({
        canvas: this.canvas,
        antialias: true
      })

      renderer.setPixelRatio(window.devicePixelRatio)

      window.addEventListener('resize', this.render, false)

      contextOBJ = this.contextObject
    },
    itemCountInRow (index) {
      return this.sceneModels.slice(
        (index - 1) * this.cardsPerRow,
        index * this.cardsPerRow
      )
    },
    attachedScenesToContainers () {
      scenes.forEach((scene) => {
        const ID = 'filter-card-index-' + scene.name
        let sc = scene.userData
        sc.element = document.getElementById(ID)

        sc.createScene.constructControls(OrbitControls, sc.element)

        // setup controls
        const controls = sc.createScene.controls
        controls.enablePan = false
        controls.target.set(0, sc.modelSettings.y, 0)
        controls.update()
        controls.addEventListener('change', this.render)

        sc.createScene = null
      })

      this.updateSceneListeneres()
    },
    updateSceneListeneres () {
      window.addEventListener('resize', this.render, false)

      this.content.addEventListener('scroll', this.render, false)

      this.$store.commit('setLoadingFlag', false)

      this.canvas.classList.remove('inactive')

      this.render()
    },
    subscribeToStore () {
      this.unsubscribeSetModelIDs = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'setModelIDs':

            const numberToSlice = this.modelIDs.length >= numberOfModelsToReturn
              ? numberOfModelsToReturn
              : this.modelIDs.length

            var models = this.modelIDs.slice(0, numberToSlice)

            this.$store.dispatch('readMultipleModelsByID', {
              models: models,
              metric: false,
              metricName: '',
              setter: 'setGridModels'
            })

            break
        }
      })

      this.unsubscribeUpdateGrid = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'setGridModels':
            if (scenes.length > 0) {
              scenes = []
            }

            scenes = createScenes(
              this.gridModels['models'],
              scenes,
              contextOBJ
            )

            this.sceneModels = scenes.map((d) => d.name)

            /**
             * async promise to ensure the DOM has updated with elements to attach
             * scenes to.
             */
            this.$nextTick((e) => {
              this.attachedScenesToContainers()
            })

            break
        }
      })
    },
    unsubscribeFromStore () {
      this.unsubscribeUpdateGrid()
      this.unsubscribeSetModelIDs()
    },
    /**
     * @default {void} calls child clearBrush function
     */
    callChildClearSelection () {
      this.$refs['parallelCoordChart'].clearBrushes()
    },
    cleanGarbage () {
      cleanScenes(scenes)
    },
    // gets models within range of parallel coordinate chart
    // then calls api for models
    readNewModels (selectedModels, dimensions) {
      // clear contents
      if (scenes.length > 0) {
        this.cleanGarbage()
        this.sceneModels = []
        scenes = []
        this.render()
      }

      this.$store.commit('setLoadingFlag', true)

      /**
       * calls megaton API to return ids sorted by weighted average
       */
      const keys = this.metricHeaders

      selectedModels = selectedModels.map((d) => {
        const obj = {}
        obj['iteration'] = d['iteration']

        keys.forEach((key, i) => {
          obj[key] = d[key]
        })

        return obj
      })

      this.modelsWithinExtent = selectedModels.length

      this.$store.dispatch('readModelIDsByWeightedAverage', {
        data: selectedModels,
        weighted_metrics: dimensions
      })
    },
    filterChartByModel (modelID, toggle) {
      this.$refs['parallelCoordChart'].highlightLineByID(modelID, toggle)
    },
    render () {
      onWindowResize(null, renderer, this.canvas.clientHeight, false)

      let el = document.getElementById('cardContainer').scrollTop

      this.canvas.style.transform = `translateY(${el}px)`

      renderScenes(scenes, renderer)
    }
  }
}
</script>
<style lang="scss">
#filterMode {
  height: calc(100vh - #{$navbar-height});
  padding: 0 2rem;
  // top: $navbar-height;
  position: relative;

  overflow: hidden;
}

.model-counter {
  margin-left: 8px;
  font-family: "Roboto";
  font-style: italic;
  letter-spacing: 1.2px;
  color: $white;
  font-size: 21px;
  font-weight: 300;

  span {
    font-size: 18px;
    letter-spacing: 0.9px;
    color: #979797;
  }
}

#parallelContainer {
  position: relative;

  padding: 2rem 0;

  background: $black;
}

#filterContent {
  position: absolute;

  height: 50vh;
  width: 98%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  &.inactive {
    display: none;
  }
  .card-row {
    margin-bottom: 6rem;
  }
}
</style>
